import { createApp } from 'vue'
import App from './App.vue'
// import vuetify from './plugins/vuetify'
// import { loadFonts } from './plugins/webfontloader'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Google Adsense settings
import ScriptX from 'vue-scriptx'
import Ads from 'vue-google-adsense'

const vuetify = createVuetify({
  components,
  directives,
})
// loadFonts()

createApp(App)
  .use(vuetify)
  .use(ScriptX)
  .use(Ads.Adsense)
  .mount('#app')
