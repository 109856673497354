<template>
  <v-container>

    <v-row>
      <v-col>
        <h1 class="display-2 font-weight-bold mb-3">
          Thank you for your support.
        </h1>
        Sadly, sporthub.me has reached End of Life (EOL). 
        It has been a great journey and I would like to express my gratitude to the people who were involved in this project. I would also like to thank you for your support.
        <p>
        If you have any feedback or suggestions, you can reach me on these social media:
        </p>
        <p>
          <ul>
            <li>
              <a href="https://twitter.com/maszuari" target="_blank">twitter.com/maszuari</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/maszuari/" target="_blank">linkedin.com/in/maszuari</a>
            </li>
          </ul>
        </p>
        <p>
            <Adsense
              data-ad-client="ca-pub-6187362941806544"
              data-ad-slot="9186582425"
              data-ad-format="auto"
              data-full-width-responsive="true"
            >
            </Adsense>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HelloWorld',

  data: () => ({
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader/tree/next',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify/tree/next',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
    importantLinks: [
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com',
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs',
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify',
      },
    ],
    whatsNext: [
      {
        text: 'Explore components',
        href: 'https://vuetifyjs.com',
      },
      {
        text: 'Roadmap',
        href: 'https://vuetifyjs.com/introduction/roadmap/',
      },
      {
        text: 'Frequently Asked Questions',
        href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
      },
    ],
  }),
}
</script>
